<template>
	<div id="myQuizScope" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
		<!-- 查询 -->
		<!-- <div class="mySearch">
			<el-input maxlength="50" v-model="keyword"
				@input="keywordInput"></el-input>
			<el-button size="small" type="primary"
				@click="search">查询</el-button>
		</div> -->
		<div class="addStyle">
			<el-button icon="el-icon-plus" size="small" type="warning" @click="addScope">新增考试范围</el-button>
		</div>
		<!-- 项目列表 -->
		<div id="mytable">
			<el-table :cell-style="rowClass" :header-cell-style="headClass" :data="tableData" border stripe>
				<template slot="empty">
					<span v-show="!listEmpty">加载中...</span>
					<span v-show="listEmpty">暂无数据</span>
				</template>
				<el-table-column type="index" :index="index" label="序号" width="80"></el-table-column>
				<el-table-column prop="scope" label="考试范围" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<span v-if="editShow!=scope.$index">{{scope.row.scope || '--' }}</span>
						 <!-- @blur="scopeBlur(scope.$index,scope.row.scope)" -->
						<span v-else>
							<el-input maxlength="50" v-model="scope.row.scope"
								@input="scopeInput(scope.$index,scope.row.scope)"></el-input>
						</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="150">
					<template slot-scope="scope">
						<el-button v-if="editShow!=scope.$index" type="text" style="color:#f56c6c;" @click="deleteScope(scope.$index,scope.row)">删除</el-button>
						<el-button v-if="editShow===scope.$index" type="text" style="color:#409EFF;"
							@click="sureScope(scope.$index,scope.row)">确定</el-button>
						<el-button v-if="editShow===scope.$index" type="text" style="color:#606266;"
							@click="cancelScope(scope.$index,scope.row)">取消</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'QuizScope',
		data() {
			return {
				// 查询
				keyword:"",
				keywordKey:"",
				tableData:[],//列表数据
				loading:false,
				submitProcessing:false,//防止多次点击
				// 需要默认给与editShow值
				editShow: "@test-测试#",
				addClick:false,//增加项目true时已经点击增加
				listEmpty:true,
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			// table序号分页显示
			index(index) {
				return index + 1;
			},
			// 查询关键字不能为空
			keywordInput(val){
				this.keyword = val.replace(/\s+/g, "")
			},
			// 输入名称不能为空
			scopeInput(index, val) {
				this.tableData[index].scope = val.replace(/\s+/g, "")
			},
			scopeBlur(index, val){
				var that=this;
				that.$axios.post("/quizScope/check",{
					scope:val
				})
				.then(function(response) {
					if(response.data.exist){
						that.$notify({
							title: '警告',
							message: '此考试范围重名!',
							type: 'warning',
							duration: 2000
						});
					}
				}).catch(function(error) {
					that.$notify({
						title: '失败',
						message: error,
						type: 'error',
						duration: 2000
					});
				});
			},
			// 点击查询
			search(){
				var that=this;
				that.keywordKey=that.keyword;
				that.getData();
			},
			// 获取所有考试范围名称数据列表
			getData() {
				let self = this;
				if (self.submitProcessing) {
					return;
				}
				self.loading = true;
				self.submitProcessing = true;
				self.tableData = [];
				self.listEmpty = false;
				self.$axios.post("/quizScope/list",{
					keyword:self.keywordKey
				}).then(function(response) {
					self.loading = false;
					self.submitProcessing = false;
					self.tableData = response.data;
					self.listEmpty = true;
				}).catch(function(error) {
					self.loading = false;
					self.submitProcessing = false;
					self.listEmpty = true;
					self.tableData = [];
					if(error=="登录失效, 请重新登录!"){}
					else{
						self.$alert(error, "提示", {
							confirmButtonText: "确定",
							type: "error",
							callback: action => {}
						});
					}
				});
			},
			//增加考试范围名称
			addScope() {
				var self = this;
				if (self.addClick) {
					return;
				}
				// 在列表的最后一行添加新的耗材
				var list = {};
				self.editShow = self.tableData.length;
				self.tableData.push(list);
				//增加
				self.addClick = true;
			},
			// 取消添加
			cancelScope(index, row) {
				var self = this;
				self.editShow = "@test-测试#";
				//如果取消添加耗材，删除最后一行
				if (self.addClick) {
					self.addClick = false;
					self.tableData.pop();
				}
			},
			// 确定提交添加
			sureScope(index, row) {
				var self = this;
				var url = "";
				var postData = "";
				// 若是增加
				if (self.addClick) {
					url = "/quizScope/add";
					postData = {
						scope: row.scope,
					}
				}
				if (self.submitProcessing) {
					return
				}
				self.submitProcessing = true;
				self.loading = true;
				self.$axios.post(url, postData).then(function(response) {
					self.loading = false;
					self.submitProcessing = false;
					self.$notify({
						title: '提示',
						message: '考试范围新增成功!',
						type: 'success',
						duration: 2000
					});
					self.addClick = false;
					self.editShow = "@test-测试#";
					self.getData();
				}).catch(function(error) {
					self.$notify({
						title: '失败',
						message: error,
						type: 'error',
						duration: 2000
					});
					self.loading = false;
					self.submitProcessing = false;
				});
			},
			// 删除
			deleteScope(index, row){
				var self = this;
				self.$confirm('确定要删除此条考试范围吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (self.submitProcessing) {
						return
					}
					self.submitProcessing = true;
					self.loading = true;
					self.$axios.put("/quizScope/remove/"+row.id).then(function(response) {
						self.loading = false;
						self.submitProcessing = false;
						self.$notify({
							title: '提示',
							message: '考试范围删除成功!',
							type: 'success',
							duration: 2000
						});
						self.addClick = false;
						self.editShow = "@test-测试#";
						self.getData();
					}).catch(function(error) {
						self.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
						self.loading = false;
						self.submitProcessing = false;
					});
				}).catch(() => {});
			},
		}
	}
</script>

<style lang='scss' scoped>
	#myQuizScope{
		max-width: 1000px;
		margin: 0 auto;
		background: #FFFFFF;
		padding: 15px;
		/* 查询 */
		.mySearch{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.el-input{
				width: 90%;
			}
			.el-button{
				margin-left: 20px;
			}
		}
		.addStyle{
			text-align: right;
		}
		/* 列表 */
		#mytable{
			margin-top: 20px;
		}
	}
</style>
